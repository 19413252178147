import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { FaTimes } from "react-icons/fa";

import Board from "../components/Board/Board";

const Child = ({ trial }) => {
  trial = trial ? 1 : 0;

  let { id, hash } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = () => {
      axios
        .get(
          process.env.REACT_APP_SYSTEM_URL +
            `/json/table/teacher-student?id=${id}&hash=${hash}&trial=` +
            trial
        )
        .then(({ data }) => {
          if (data && data.id) {
            setData(data);
          } else {
            setError(true);
          }
        });
    };
    fetch();
    console.log("child", trial);
  }, [id, hash, trial]);

  return (
    <>
      {error && (
        <p style={{ fontSize: "35px", textAlign: "center" }}>
          <FaTimes /> Nie znaleziono
        </p>
      )}
      {data && <Board data={data} trial={trial} />}
    </>
  );
};

export default Child;
