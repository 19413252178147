import React, { useEffect, useState } from "react";
import axios from "axios";

import Canvas from "./Canvas";
import Abacus from "./Abacus";

import { Lib, LibPage } from "./BoardStyled";
import { Nav, Container } from "../App/AppStyled";

import { FaAngleRight, FaUserAlt } from "react-icons/fa";

const Board = ({ data, trial }) => {
  const room = data.id;
  const name = data.name;

  const [modules, setModules] = useState([]);
  const [activeBook, setActiveBook] = useState(null);
  const [page, setPage] = useState(null);

  const handleActiveBook = (name) => {
    setActiveBook(activeBook !== name ? name : null);
  };

  useEffect(() => {
    const fetch = () => {
      axios
        .get(process.env.REACT_APP_API_URL + "/data.json")
        .then(({ data }) =>
          setModules(
            trial
              ? data.lessons.geniusTrial.modules
              : data.lessons.genius.modules
          )
        );
    };
    fetch();
    setPage(null);
    console.log("board load");
  }, [room, trial]);

  return (
    <>
      {modules && !page && (
        <>
          <Nav>
            <img
              src="https://system.geniuspolska.com/img/logo.png"
              alt="logo"
              style={{ display: "block", height: "50px", margin: "0 auto" }}
            />
          </Nav>
          <Container bg="transparent">
            <h3 style={{ marginTop: 0, textAlign: "center" }}>
              <FaUserAlt /> {name}
            </h3>
            {modules.map((module) => (
              <Lib key={module.name}>
                <h4 onClick={() => handleActiveBook(module.name)}>
                  <FaAngleRight /> {module.displayName}
                </h4>
                <div
                  className="grid"
                  style={{
                    display: activeBook === module.name ? "grid" : "none",
                  }}
                >
                  {module.pages.map((page) => (
                    <LibPage
                      key={page.name}
                      onClick={() =>
                        setPage({
                          module: module.name,
                          page: page.name,
                          image: process.env.REACT_APP_API_URL + page.url,
                        })
                      }
                    >
                      <img
                        src={process.env.REACT_APP_API_URL + page.thumb}
                        alt={page.name}
                      />
                      <h3>{page.name}</h3>
                    </LibPage>
                  ))}
                </div>
              </Lib>
            ))}
          </Container>
        </>
      )}

      {page && <Abacus room={room} data={page} trial={trial} />}
      {page && <Canvas room={room} data={page} back={() => setPage(null)} />}
    </>
  );
};

export default Board;
