import React, { Component, createRef } from "react";
import AppContext from "../App/AppContext";
import { fabric } from "fabric";
import axios from "axios";

import { Nav } from "../App/AppStyled";
import { Wrapper, Button, CanvasWrapper } from "./CanvasStyled";

import {
  FaPencilAlt,
  FaFont,
  FaHandPaper,
  FaTrash,
  FaArrowsAlt,
  FaMinus,
  FaPlus,
  FaExpand,
  FaArrowLeft,
} from "react-icons/fa";

class Canvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tool: "pan",
      zoom: 100,
    };
    this.containerRef = createRef();
    this.canvas = null;
    this.toolDescriptions = {
      pan: {
        name: "Kamera",
        description: "Przemieszczaj się po całej kartce",
      },
      draw: {
        name: "Rysowanie",
        description: "Kliknij aby zacząć rysować",
      },
      move: {
        name: "Ruszaj",
        description: "Naciśnij na element aby go ruszyć lub usunąć",
      },
      text: {
        name: "Tekst",
        description: "Kliknij w miejsce w którym chcesz zacząć pisać",
      },
    };
  }

  componentDidMount() {
    console.log("room name", this.roomName());
    console.log("mount", this.props.data);

    this.context.socket.emit("join", this.roomName());

    this.canvas = new fabric.Canvas(this.containerRef.current, {
      isDrawingMode: true,
      width: 1000,
      height: 1000,
      selection: false,
    });

    this.canvas.freeDrawingBrush.width = 4;
    this.canvas.freeDrawingBrush.color = "black";

    axios
      .get(process.env.REACT_APP_API_URL + "/tmp/" + this.roomName() + ".json")
      .then(({ data }) => {
        try {
          this.canvas.loadFromJSON(data);
        } catch (e) {
          console.log("blad jsona, zapisz stare", data);
        }
      })
      .catch((e) => {
        console.log("err", e);
      });

    window.$canvas = this.canvas;

    var img = document.createElement("img");
    img.draggable = false;
    img.src = this.props.data.image;
    this.containerRef.current.parentNode.appendChild(img);

    let textTimeout;

    this.canvas
      .on("object:added", (e) => {
        console.log("object:added", e.target.type, e);

        if (e.target.id) {
          return;
        }

        e.target.id = Date.now() + "-" + Math.random();

        let emitData = {};

        if (e.target.type === "path") {
          let path = e.target;
          let size1 = JSON.stringify(path.path).length / 1024;

          console.log(path.path.length);

          if (path.path.length <= 2 || path.path.length > 500) {
            path.disableEvents = true;
            this.canvas.remove(path);
            console.log("path wrong size");
            return;
          }

          path.set({
            path: this.compressPath(path.path),
          });

          emitData.path = this.pathToString(e.target.path);
          emitData.width = path.strokeWidth;
          emitData.color = path.stroke;

          let size2 = JSON.stringify(emitData.path).length / 1024;
          console.log(
            "path compress",
            [size2 + " kb"],
            [((size1 / size2) * 100).toFixed(2) + "%"]
          );
        } else if (e.target.type === "i-text") {
          console.log("itext", e);
          emitData.fontSize = e.target.fontSize;
          emitData.color = e.target.color;
        } else {
          return;
        }

        emitData.id = e.target.id;
        emitData.type = e.target.type;
        emitData.room = this.roomName();

        console.log("%c Emit ADD object", "color: red");
        console.log(emitData);

        this.context.socket.emit("objectAdd", emitData);

        this.saveObject();
      })
      .on("object:removed", (e) => {
        if (e.target.disableEvents) {
          return;
        }

        console.log("object:removed", e);

        let emitData = {};
        emitData.id = e.target.id;
        emitData.room = this.roomName();

        console.log("%c Emit remove object", "color: red");
        this.context.socket.emit("objectRemove", emitData);
        console.log(emitData);

        this.saveObject();
      })
      .on("text:changed", (e) => {
        clearTimeout(textTimeout);
        console.log("t", e);
        textTimeout = setTimeout(() => {
          console.log("> send text");
          this.objectModify(e);
        }, 500);
      })
      .on("object:modified", this.objectModify);

    this.canvas.on("mouse:down", (e) => {
      if (this.state.tool !== "text") {
        return;
      }

      if (e.target) {
        return;
      }

      this.openTextEditor(e);
      //this.setTool("move");
    });

    this.context.socket.on("objectAdd", (data) => {
      if (data.room !== this.roomName()) {
        return;
      }

      console.log(data);
      if (data.type === "path") {
        var path = new fabric.Path(data.path);
        path.set({
          id: data.id,
          stroke: data.color,
          fill: "transparent",
          strokeWidth: data.width,
        });
        this.canvas.add(path);
      } else if (data.type === "i-text") {
        let text = new fabric.IText("", {
          id: data.id,
          fontSize: data.fontSize,
          color: data.color,
        });
        this.canvas.add(text);
      }
    });

    this.context.socket.on("objectModify", (data) => {
      if (data.room !== this.roomName()) {
        return;
      }

      let object = this.canvas.getObjects().find((obj) => obj.id === data.id);
      if (!object) {
        return;
      }

      object.set({
        left: data.left,
        top: data.top,
        scaleX: data.scaleX,
        scaleY: data.scaleY,
        angle: data.angle,
      });

      if (data.text) {
        object.set({ text: data.text });
      }

      object.setCoords();
      this.canvas.renderAll();

      console.log("objectModify", data);
    });

    this.context.socket.on("objectRemove", (data) => {
      if (data.room !== this.roomName()) {
        return;
      }

      let object = this.canvas.getObjects().find((obj) => obj.id === data.id);
      if (!object) {
        return;
      }

      this.canvas.remove(object);
      console.log("objectRemove", data);
    });
  }

  componentWillUnmount() {
    console.log("unmount");

    this.context.socket.off("objectAdd");
    this.context.socket.off("objectModify");
    this.context.socket.off("objectRemove");

    this.context.socket.emit("leave", this.roomName());
  }

  roomName = () => {
    return (
      "child_" +
      this.props.room +
      "-book_" +
      this.props.data.module +
      "-page_" +
      this.props.data.page
    );
  };

  saveObject = () => {
    let data = this.canvas.toJSON(["id"]);
    data.objects.forEach((obj) => {
      if (obj && obj.path) {
        obj.path = this.pathToString(obj.path);
      }
    });
    console.log("SAVE!!!!!!", data);
    axios.post(process.env.REACT_APP_API_URL + "/save-json", {
      room: this.roomName(),
      data: JSON.stringify(data),
    });
  };

  objectAdd = (e) => {};

  objectModify = (e) => {
    console.log("object:modified", e);

    let emitData = {};
    emitData.id = e.target.id;

    if (e.target.text) {
      emitData.text = e.target.text;
    }

    emitData.left = e.target.left;
    emitData.top = e.target.top;
    emitData.scaleX = e.target.scaleX;
    emitData.scaleY = e.target.scaleY;
    emitData.angle = e.target.angle;

    emitData.room = this.roomName();

    console.log("%c Emit Edit object", "color: red");

    this.context.socket.emit("objectModify", emitData);
    console.log(emitData);

    this.saveObject();
  };

  objectRemove = (e) => {};

  pathToString(path) {
    let result = "";

    path.forEach((e1, key1) => {
      e1.forEach((e2, key2) => {
        result = result + e2 + " ";
      });
    });

    return result;
  }

  compressPath(path) {
    path.forEach((e1, key1) => {
      e1.forEach((e2, key2) => {
        if (typeof e2 === "number") {
          path[key1][key2] = parseInt(e2);
        }
      });
    });

    path.forEach((e, key) => {
      if (e[0] === "Q" && key % 2 === 0) {
        path.splice(key, 1);
      }
    });

    return path;
  }

  openTextEditor(e) {
    let text = new fabric.IText("", {
      fontSize: 25,
      color: "red",
      left: e.pointer.x,
      top: e.pointer.y - 12,
    });

    this.canvas.add(text).setActiveObject(text);

    text.enterEditing();
  }

  setTool(tool) {
    this.setState({
      tool: tool,
    });

    if (tool === "draw") {
      this.canvas.isDrawingMode = true;
    } else if (tool === "move") {
      this.canvas.isDrawingMode = false;
    } else if (tool === "text") {
      this.canvas.isDrawingMode = false;
    }
  }

  trash() {
    this.setTool("move");

    let objects = this.canvas.getActiveObjects();
    objects.forEach((obj) => {
      this.canvas.remove(obj);
    });
    this.canvas.discardActiveObject();
  }

  zoomPlus() {
    this.setState((state) => {
      return {
        zoom: state.zoom < 300 ? state.zoom + 20 : 300,
      };
    });
  }

  zoomMinus() {
    this.setState((state) => {
      return {
        zoom: state.zoom > 100 ? state.zoom - 20 : 100,
      };
    });
  }

  zoomInit() {
    this.setState({
      zoom: 100,
    });
  }

  render() {
    return (
      <>
        <Wrapper>
          <Nav>
            <Button onClick={() => this.props.back()}>
              <FaArrowLeft />
            </Button>
            <Button
              active={this.state.tool === "pan"}
              onClick={() => this.setTool("pan")}
            >
              <FaHandPaper />
            </Button>
            <Button
              active={this.state.tool === "draw"}
              onClick={() => this.setTool("draw")}
            >
              <FaPencilAlt />
            </Button>
            <Button
              active={this.state.tool === "move"}
              onClick={() => this.setTool("move")}
            >
              <FaArrowsAlt />
            </Button>
            <Button
              active={this.state.tool === "text"}
              onClick={() => this.setTool("text")}
            >
              <FaFont />
            </Button>
            <Button onClick={() => this.trash()}>
              <FaTrash />
            </Button>

            <Button onClick={() => this.zoomMinus()}>
              <FaMinus />
            </Button>
            <Button onClick={() => this.zoomInit()}>
              <FaExpand />
            </Button>
            <Button onClick={() => this.zoomPlus()}>
              <FaPlus />
            </Button>

            {/* {this.toolDescriptions[this.state.tool] && (
              <p style={{ fontSize: "10px", color: "#272727", marginTop: 0 }}>
                <b>{this.toolDescriptions[this.state.tool].name}</b>:{" "}
                {this.toolDescriptions[this.state.tool].description}
              </p>
            )} */}
          </Nav>

          {/* <Container>
            <b>Room {this.state.room}</b>
          </Container> */}

          <CanvasWrapper
            pan={this.state.tool === "pan"}
            zoom={this.state.zoom / 100}
          >
            <canvas ref={this.containerRef} />
          </CanvasWrapper>
        </Wrapper>
      </>
    );
  }
}
Canvas.contextType = AppContext;

export default Canvas;
